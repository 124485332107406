/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ platform: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_last_giraffe_scoop = (params) => `U heeft de toestemming geweigerd. Om het later te activeren, moet u naar de app-voorkeuren gaan onder de instellingen ${params.platform} en het handmatig inschakelen.`
