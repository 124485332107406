/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ platform: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_last_giraffe_scoop = (params) => `Tinanggihan mo ang pahintulot. Upang ma-activate ito sa ibang pagkakataon, kailangan mong pumunta sa mga kagustuhan sa app sa ilalim ng mga setting ${params.platform} at manu-manong paganahin ito.`
