import { isAndroid, isWeb } from '$lib/capacitor';
import { Dialog } from '$lib/components/Modal/ProgrammaticDialog.svelte';
import * as m from '$lib/paraglide/messages.js';
import { LocalNotifications } from '@capacitor/local-notifications';

export async function _doPermissionsCheck() {
	const result = await LocalNotifications.checkPermissions();
	if (result.display === 'prompt' || result.display === 'prompt-with-rationale') {
		const status = await LocalNotifications.requestPermissions();
		if (status.display === 'denied') {
			const platform = isAndroid() ? 'Android' : isWeb() ? 'Web' : 'iOS';
			Dialog.show({
				title: m.odd_day_tadpole_edit(),
				content: m.tangy_last_giraffe_scoop({ platform }),
				buttonTitle: m.dry_strong_lemming_nurture(),
			});
		}
	}
}
