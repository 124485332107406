export const testDailyReminderNotificationID = 4001;
export const testReminderIn5Seconds = 4002;
export const currentDefaultNotificationID = 2;
export const initialReleaseNotificationID = 4711;

export const allAllowedNotificationIDs = [
	testDailyReminderNotificationID,
	testReminderIn5Seconds,
	currentDefaultNotificationID,
];
