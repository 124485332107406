/* eslint-disable */
import { languageTag } from "../../runtime.js"
import * as en from "../en/tangy_last_giraffe_scoop.js"
import * as nl from "../nl/tangy_last_giraffe_scoop.js"
import * as fil from "../fil/tangy_last_giraffe_scoop.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ platform: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "nl" | "fil" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_last_giraffe_scoop = (params , options = {}) => {
	return {
		en: en.tangy_last_giraffe_scoop,
		fil: fil.tangy_last_giraffe_scoop,
		nl: nl.tangy_last_giraffe_scoop
	}[options.languageTag ?? languageTag()](params)
}

